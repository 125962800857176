html,
body {
  font-size: 20px;
  color: #212121;
  background-color: #ffffff;
}

html,
body,
#root {
  height: 100%;
}
